import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { appUrl } from 'src/app/app.url';
import { HttpService } from 'src/app/shareds/services/http.service';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss']
})
export class AddProductComponent implements OnInit {

  constructor(private http: HttpService,
    private build: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dom: DomSanitizer,
    private app: AppService) { }

  bootname: string;
  bootid: number;
  active: string;
  productId: number;
  status: string;
  ngOnInit() {
    this.bootname = this.activatedRoute.snapshot.params['name'];
    this.bootid = this.activatedRoute.snapshot.params['id'];
    this.active = this.activatedRoute.snapshot.params['action'];
    this.status = this.activatedRoute.snapshot.params['status'];
    this.productId = this.activatedRoute.snapshot.params['actionid'];
    this.formControl();
    if (this.productId && this.status == 'update') this.getItemsProduct()
  }


  imRes: any;
  getItemsProduct() {
    this.http.methodGet(`bo/productmanage/get/${this.productId}`).subscribe(res => {
      this.imRes = res;
      this.form.patchValue(this.imRes);
      if (this.imRes.PDF) this.URLPdf = { name: this.imRes.PDF, url: `${environment.images}${this.imRes.PDF}` }
      if (this.imRes.Video) this.URLVideo = { name: this.imRes.Video, url: `${environment.video}${this.imRes.Video}` }
      if (this.imRes.VideoImage) this.URLimagesVideo = { name: this.imRes.VideoImage, url: `${environment.images}${this.imRes.VideoImage}` }
      if (this.imRes.Images.length > 0) this.imRes.Images.forEach(f => { this.arrayURLImages.push({ name: f.Image, url: `${environment.images}${f.Image}` }) });
    })
  }

  form: FormGroup;
  formControl() {
    this.form = this.build.group({
      // prodId: this.productId || [""],
      PositionX: 0,
      PositionY: 0,
      ProductName: ["", Validators.required],
      VideoFile: ["", Validators.required],
      VideoImage: [""],
      Images: ["", Validators.required],
      ProductDetail: ["", Validators.required],
      PDFFile: [""],
      ProductContact: [""],
      UpdateImage: [""]
    })
  }

  cancel() {
    // this.router.navigate(['/', appUrl.boothmanage])
    this.router.navigate(['/', appUrl.boothmanage, this.bootname, 'booth', this.bootid, this.active])
  }

  upload() {
    this.form.get('Images').setValue(this.fileimages);
    this.form.get('UpdateImage').setValue(this.updateFileItems);
    this.http.uploadfilePUT(`bo/productmanage/${this.productId}`, this.form.value).subscribe(res => {
      alert('แก้ไขสินค้าแล้ว');
      this.router.navigate(['/', appUrl.boothmanage, this.bootname, 'booth', this.bootid, this.active])
    })
  }

  create() {
    this.form.get('Images').setValue(this.fileimages);
    if (!this.form.valid) return alert("กรอกข้อมูลที่มี * ให้ครบ");
    this.http.uploadfilePOST(`bo/productmanage/create/${this.bootid}`, this.form.value).subscribe(res => {
      alert('เพิ่มสินค้าแล้ว');
      this.router.navigate(['/', appUrl.boothmanage, this.bootname, 'booth', this.bootid, this.active])
    })
  }

  selectFile(items) {
    items.click();
  }

  fileimages: any = [];
  arrayURLImages: any = [];
  URLimagesVideo: any;
  URLVideo: any;
  URLPdf: any;
  progressBar: number;
  selectImages(event, type: string, keyupload: string) {
    this.progressBar = 0;
    const feedback = document.getElementById('feedback');
    const progresss: any = document.getElementById('progress');
    const file = event.target.files && event.target.files[0];
    if (file) {
      file.status = 'create';
      var reader = new FileReader();
      reader.readAsDataURL(file);
      if (file.type.indexOf(`${type}`) > -1) {
        if (keyupload != 'video') reader.onload = (event) => {
          if (keyupload == 'img') {
            this.URLimagesVideo = ""
            this.URLimagesVideo = { name: file.name, url: (<FileReader>event.target).result }
            this.form.get('VideoImage').setValue([file])
          }
          if (keyupload == 'imgs') {
            this.arrayURLImages.push({ name: file.name, url: (<FileReader>event.target).result });
            this.fileimages.push(file);
          }
          if (keyupload == 'pdf') {
            this.URLPdf = ""
            this.URLPdf = { name: file.name, url: this.dom.bypassSecurityTrustUrl(URL.createObjectURL(file)) }
            this.form.get('PDFFile').setValue([file])
          }
        }
        if (keyupload == 'video') reader.onprogress = (event) => {
          if (event.loaded > 1153209714) {
            let msg = `<span style="color:red;">ไฟล์ขนาดเกินกำหนด</span>`;
            feedback.innerHTML = msg;
            return;
          } else {
            if (event.loaded && event.total) {
              if (keyupload == 'video') {
                const percent = (event.loaded / event.total) * 100;
                progresss.value = percent;
                this.progressBar = progresss.value;
                document.getElementById('progress-label').innerHTML = Math.round(percent) + '%';
                if (percent === 100) {
                  let msg = `<span style="color:green;">File <u><b>${file.name}</b></u>.</span>`;
                  feedback.innerHTML = msg;
                  this.URLVideo = "";
                  setTimeout(() => {
                    // this.URLVideo = { name: file.name, url: (<FileReader>event.target).result }
                    this.form.get('VideoFile').setValue([file])
                  }, 500);
                }
              }
            }
          }
        }


      } else {
        event.target.value = "";
        if (keyupload == 'video') return alert("ต้องเป็นวิดีโอเท่านั้น");
        if (keyupload == 'pdf') return alert("ต้องเป็น PDF นั้น");
        else alert("ต้องเป็นรูปภาพเท่านั้น");

      }

    }
  }

  updateFileItems: any[] = [];
  deleteImg(items) {
    this.arrayURLImages.splice(this.arrayURLImages.findIndex(del => del.name == items.name), 1);
    this.fileimages.splice(this.fileimages.findIndex(filedel => filedel.name == items.name), 1);
    // this.updateFileItems.splice(this.updateFileItems.findIndex(fi => fi.name == items.name), 1)
    this.updateFileItems.push({ name: items.name, status: "delete" });
  }


  loadSize(items, img) {
    if (img.naturalWidth < 1280 && img.naturalHeight < 720) {
      this.arrayURLImages.splice(this.arrayURLImages.indexOf(del => del == items), 1);
      this.fileimages.splice(this.fileimages.indexOf(del => del.name == items.name), 1);
      alert('ขนาดรูปไม่ตรงที่ต้องการ');
      return
    }
  }

  // loadVdoSize(items, img) {
  //   if (img.naturalWidth < 1280 && img.naturalHeight < 720) {
  //     this.arrayURLImages.splice(this.arrayURLImages.indexOf(del => del == items), 1);
  //     this.fileimages.splice(this.fileimages.indexOf(del => del.name == items.name), 1);
  //     alert('ขนาดรูปไม่ตรงที่ต้องการ');
  //     return
  //   }
  // }
}
