import { Component, OnInit } from '@angular/core';
import { AppService } from '../../../../app.service';
import { Router, ActivatedRoute } from '@angular/router';
import { appUrl } from '../../../../app.url';
import { HttpService } from '../../../../shareds/services/http.service';

@Component({
  selector: 'app-booth-button',
  templateUrl: './booth-button.component.html',
  styleUrls: ['./booth-button.component.scss']
})
export class BoothButtonComponent implements OnInit {
  bootname: string;
  bootid: number;
  active: string;
  constructor(public app: AppService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private http: HttpService) { }

  ngOnInit() {
    this.bootname = this.activatedRoute.snapshot.params['name'];
    this.bootid = this.activatedRoute.snapshot.params['id'];
    this.active = this.activatedRoute.snapshot.params['action'];
    this.getItems();
  }

  itemsButton: any;
  getItems() {
    this.http.methodGet(`api/BOBoothAccessories/Find?boothId=${this.bootid}`).subscribe(res => {
      this.itemsButton = res['data'];
    })
  }

  addButton() {
    this.router.navigate(['/', appUrl.boothmanage, this.bootname, 'booth', this.bootid, this.active, 'add', 'button', '^^'])
  }

  updateButton(items: any) {
    this.router.navigate(['/', appUrl.boothmanage, this.bootname, 'booth', this.bootid, this.active, 'update', 'button', items.ba_id])
  }

  delete(items) {
    let txt;
    let alert = confirm("ต้องการลบ ?");
    if (alert == true) {
      this.http.methodDelete(`api/BOBoothAccessories/${items.ba_id}`).subscribe(res => {
        this.getItems();
      })
    } else {
      txt = "You pressed Cancel!";
    }
  }
}
