import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../../shareds/services/http.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { appUrl } from '../../../app.url';
// declare let moment: any;
import * as moment from 'moment';
@Component({
  selector: 'app-add-seminar',
  templateUrl: './add-seminar.component.html',
  styleUrls: ['./add-seminar.component.scss']
})
export class AddSeminarComponent implements OnInit {

  seminarid; any;
  constructor(private http: HttpService,
    private build: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute) {

  }

  ngOnInit() {
    this.seminarid = this.activatedRoute.snapshot.params['id'];
    if (this.seminarid) this.http.methodGet(`api/BOSeminar/${this.seminarid}`).subscribe(res => {
      this.form.patchValue(res['data'])
      this.form.get('show_date').setValue(moment(res['data']['show_date']).format('YYYY-MM-DD'))
    })
    this.formControl();
  }

  form: FormGroup;
  formControl() {
    this.form = this.build.group({
      seminar_title: ["", Validators.required],
      seminar_name: ["", Validators.required],
      seminar_code: "",
      seminar_meeting_id: "",
      seminar_passcode: "",
      seminar_link_access: ["", Validators.required],
      duration: ["", Validators.required],
      show_date: ["", Validators.required],
      seminar_isactive: true
    })
  }

  createRoom() {
    if (!this.form.valid) alert('กรอกข้อมูลที่มี * ไม่ครบ')
    else this.http.methodPost(`api/BOSeminar`, this.form.value).subscribe(res => {
      this.back();

    })

  }

  updateRoom() {
    if (!this.form.valid) alert('กรอกข้อมูลที่มี * ไม่ครบ')
    else this.http.methodPut(`api/BOSeminar/${this.seminarid}`, this.form.value).subscribe(res => {
      this.back();
    })
  }

  back() {
    this.router.navigate(['/', appUrl.meetingroom]);
  }
}
