import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { appUrl } from '../../app.url';
import { HttpService } from '../../shareds/services/http.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

  constructor(private router: Router,
    private http: HttpService) { }

  ngOnInit() {
    this.getItems();
  }

  itemPro: any = [];
  getItems() {
    this.http.methodGet(`api/BOCommunityProduct/Find`).subscribe(res => {
      this.itemPro = res['data'];
    })
  }

  detail(items: any) {
    this.router.navigate(['/', appUrl.product, 'update', items.community_prod_id]);
  }

  addProduct() {
    this.router.navigate(['/', appUrl.product, 'add']);
  }

  deleteProuct(items) {
    let txt;
    let alert = confirm("ต้องการลบ ?");
    if (alert == true) {
      this.http.methodDelete(`api/BOCommunityProduct/${items.community_prod_id}`).subscribe(res => {
        this.getItems();
      })
    } else {
      txt = "You pressed Cancel!";
    }

  }








}
