import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HttpService } from '../../../shareds/services/http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { appUrl } from '../../../app.url';

@Component({
  selector: 'app-add-schedulevents',
  templateUrl: './add-schedulevents.component.html',
  styleUrls: ['./add-schedulevents.component.scss']
})
export class AddScheduleventsComponent implements OnInit {
  bootname: any;
  bootid: any;
  active: any;
  status: any;
  productId: any;
  constructor(private build: FormBuilder,
    private http: HttpService,
    private activatedRoute: ActivatedRoute,
    private router: Router) {
    this.bootname = this.activatedRoute.snapshot.params['name'];
    this.bootid = parseInt(this.activatedRoute.snapshot.params['id']);
    this.active = this.activatedRoute.snapshot.params['action'];
    this.status = this.activatedRoute.snapshot.params['status'];
    this.productId = this.activatedRoute.snapshot.params['actionid'];
  }

  ngOnInit() {
    this.formControl();
    if (this.bootid) this.getItemsID();
  }

  form: FormGroup;
  formControl() {
    this.form = this.build.group({
      TimeInfo: [''],
      Name: [''],
      Detail: ['']
    })
  }

  getItemsID() {
    this.http.methodGet(`api/BOScheduleEvents/${this.bootid}`).subscribe(res => {
      // console.log(res['data']);
      this.form.get('TimeInfo').setValue(res['data']['se_time_info'])
      this.form.get('Name').setValue(res['data']['se_name'])
      this.form.get('Detail').setValue(res['data']['se_detail'])
    })
  }

  createEvenet() {
    this.http.methodPost(`api/BOScheduleEvents`, this.form.value).subscribe(res => {
      this.router.navigate(['/', appUrl.schedulevents])
    })
  }

  updateEvenet() {
    this.http.methodPut(`api/BOScheduleEvents/${this.bootid}`, this.form.value).subscribe(res => {
      this.router.navigate(['/', appUrl.schedulevents])
    })
  }

  cancel() {
    this.router.navigate(['/', appUrl.schedulevents])
  }

}
