import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../shareds/services/http.service';

@Component({
  selector: 'app-setting-page',
  templateUrl: './setting-page.component.html',
  styleUrls: ['./setting-page.component.scss']
})
export class SettingPageComponent implements OnInit {

  constructor(private http: HttpService) { }

  ngOnInit() {
    this.getItems();
  }

  Login: boolean;
  getItems() {
    this.http.methodGet(`api/BOLandingPageSetting/RegisterBeforeEntryStatus`).subscribe(res => {
      this.Login = res['data'];
    })
  }

  checkStatus(items) {
    this.http.methodPut(`api/BOLandingPageSetting/RegisterBeforeEntryStatus`, null).subscribe(res => {
      this.Login = !items;
    })
  }
}
