import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { appUrl } from 'src/app/app.url';
import { HttpService } from 'src/app/shareds/services/http.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-add-booth',
  templateUrl: './add-booth.component.html',
  styleUrls: ['./add-booth.component.scss']
})
export class AddBoothComponent implements OnInit {
  zoneItems: any = [
    { id: 1, name: 'Ai' },
    { id: 2, name: 'Big Data' },
    { id: 3, name: 'Ai + Big Data' },
    { id: 4, name: 'อื่นๆ' },
  ]
  constructor(private http: HttpService,
    private build: FormBuilder,
    private router: Router,
    private app: AppService,
    private activatedRoute: ActivatedRoute) { }

  boothId: number;
  progressBar: number;
  ngOnInit() {
    this.formControl();
    this.boothId = this.activatedRoute.snapshot.params['id'];
    if (this.boothId) {
      this.http.methodGet(`api/BOBooth/${this.boothId}`).subscribe(res => {
        this.form.patchValue(res['data']);
        if (this.form.value['booth_img']) this.URLimages = { name: this.form.value['booth_img'], url: `${environment.images}/${this.form.value['booth_img']}` };
        if (this.form.value['booth_contact_img']) this.URLimagesQRCODE = { name: this.form.value['booth_contact_img'], url: `${environment.images}/${this.form.value['booth_contact_img']}` };
        // if (this.form.value['Video']) this.URLVideo = { name: this.form.value['Video'], url: `${this.app.vodURL}/${this.form.value['Video']}` };


      })

    }
  }

  form: FormGroup;
  formControl() {
    this.form = this.build.group({
      booth_name: [''],
      booth_img: [''],
      booth_img_is_edit: [false],
      booth_contact: [''],
      booth_contact_img: [''],
      booth_contact_img_is_edit: [false],
      booth_isactive: [true],
    })
  }

  fileimages: any = [];
  URLimages: any;
  URLimagesQRCODE: any;
  URLVideo: any;
  selectImages(event, type: string, keyupload: string) {
    this.progressBar = 0;
    const feedback = document.getElementById('feedback');
    const progresss: any = document.getElementById('progress');
    const file = event.target.files && event.target.files[0];
    if (file) {
      file.status = 'create';
      var reader = new FileReader();
      reader.readAsDataURL(file);
      if (file.type.indexOf(`${type}`) > -1) {
        if (keyupload == 'img') reader.onload = (event) => {
          if (keyupload == 'img') {
            this.URLimages = { name: file.name, url: (<FileReader>event.target).result }
            this.form.get('booth_img').setValue([file]);
            if (this.boothId) this.form.get('booth_img_is_edit').setValue(true)
          }
        }
        if (keyupload == 'QRCODE') reader.onload = (event) => {
          if (keyupload == 'QRCODE') {
            this.URLimagesQRCODE = { name: file.name, url: (<FileReader>event.target).result }
            this.form.get('booth_contact_img').setValue([file]);
            if (this.boothId) this.form.get('booth_contact_img_is_edit').setValue(true)
          }
        }
        if (keyupload == 'video') reader.onprogress = (event) => {
          if (event.loaded > 1153209714) {
            let msg = `<span style="color:red;">ไฟล์ขนาดเกินกำหนด</span>`;
            feedback.innerHTML = msg;
            return;
          } else {
            if (event.loaded && event.total) {
              const percent = (event.loaded / event.total) * 100;
              progresss.value = percent;
              this.progressBar = progresss.value;
              document.getElementById('progress-label').innerHTML = Math.round(percent) + '%';
              if (percent === 100) {
                if (keyupload == 'video') {
                  let msg = `<span style="color:green;">File <u><b>${file.name}</b></u>.</span>`;
                  feedback.innerHTML = msg;
                  // this.URLVideo = { name: file.name, url: (<FileReader>event.target).result }
                  this.form.get('Video').setValue([file]);
                }
                // if (keyupload == 'img') {
                //   this.URLimages = { name: file.name, url: (<FileReader>event.target).result }
                //   this.form.get('LogoImage').setValue([file]);
                // }
              }
            }
          }


        }

      } else {
        event.target.value = "";
        if (keyupload == 'video') alert("ต้องเป็นวิดีโอเท่านั้น")
        else {
          alert("ต้องเป็นรูปภาพเท่านั้น")
        }
      }

    }
  }

  createBooth() {
    this.http.uploadfilePOST2(`api/BOBooth`, this.form.value).subscribe(res => {
      alert('สร้างบูธแล้ว')
      this.cancel();
    })
  }

  updateBooth() {
    this.http.uploadfilePUT2(`api/BOBooth/${this.boothId}`, this.form.value).subscribe(res => {
      alert('แก้ไขแล้ว')
      this.cancel();
    })
  }

  cancel() {
    this.router.navigate(['/', appUrl.boothmanage])
  }

  selectFile(items) {
    items.click();
  }


}
