import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from '../../../shareds/services/http.service';
import { appUrl } from '../../../app.url';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-add-product-main',
  templateUrl: './add-product-main.component.html',
  styleUrls: ['./add-product-main.component.scss']
})
export class AddProductMainComponent implements OnInit {

  constructor(private build: FormBuilder,
    private dom: DomSanitizer,
    private router: Router,
    private http: HttpService,
    private activatedRoute: ActivatedRoute) {

  }

  form: FormGroup
  formControl() {
    this.form = this.build.group({
      community_prod_title_name: "",
      community_prod_title_detail: "",
      community_prod_title_img: "",
      community_prod_isactive: true,
      community_prod_content_texts: this.build.array([
        this.build.group({
          community_prod_content_name: '',
          community_prod_content_detail: ''
        })
      ]),
      community_prod_content_images: this.build.array([]),
    })

  }

  bootname: string;
  proid: number;
  active: string;
  productId: number;
  status: string;
  imRes: any;
  ngOnInit() {
    this.formControl();
    this.bootname = this.activatedRoute.snapshot.params['name'];
    this.proid = this.activatedRoute.snapshot.params['id'];
    this.active = this.activatedRoute.snapshot.params['action'];
    this.status = this.activatedRoute.snapshot.params['status'];
    this.productId = this.activatedRoute.snapshot.params['actionid'];
    this.http.methodGet(`api/BOCommunityProduct/${this.proid}`).subscribe(res => {
      this.imRes = res['data']
      if (this.imRes.community_prod_content_images.length > 0) this.imRes.community_prod_content_images.forEach(f => this.arrayURLImages.push({ name: f, url: `${environment.images}/${f.community_prod_content_image}` }));
      if (this.imRes) this.form.patchValue(this.imRes);
    })
  }

  URLimages: any;
  arrayURLImages: any = [];
  fileimages: any = [];
  progressBar: any;
  URLPdf: any;
  URLVideo: any
  selectImg(event, type?: string, keyupload?: string) {
    this.progressBar = 0;
    const feedback = document.getElementById('feedback');
    const progresss: any = document.getElementById('progress');
    const file = event.target.files && event.target.files[0];
    if (file) {
      file.status = 'create';
      var reader = new FileReader();
      reader.readAsDataURL(file);
      if (file.type.indexOf(`${type}`) > -1) {
        if (keyupload != 'video') reader.onload = (event) => {
          if (keyupload == 'img') {
            this.URLimages = ""
            this.URLimages = { name: file.name, url: (<FileReader>event.target).result, status: 'create' }
            this.form.get('community_prod_title_img').setValue([file])
          }
          if (keyupload == 'imgs') {
            this.arrayURLImages.push({ name: file.name, url: (<FileReader>event.target).result });
            this.fileimages.push([file]);
          }
          if (keyupload == 'pdf') {
            this.URLPdf = ""
            this.URLPdf = { name: file.name, url: this.dom.bypassSecurityTrustUrl(URL.createObjectURL(file)) }
            this.form.get('PDFFile').setValue([file])
          }
        }
        if (keyupload == 'video') reader.onprogress = (event) => {
          if (event.loaded > 1153209714) {
            let msg = `<span style="color:red;">ไฟล์ขนาดเกินกำหนด</span>`;
            feedback.innerHTML = msg;
            return;
          } else {
            if (event.loaded && event.total) {
              if (keyupload == 'video') {
                const percent = (event.loaded / event.total) * 100;
                progresss.value = percent;
                this.progressBar = progresss.value;
                document.getElementById('progress-label').innerHTML = Math.round(percent) + '%';
                if (percent === 100) {
                  let msg = `<span style="color:green;">File <u><b>${file.name}</b></u>.</span>`;
                  feedback.innerHTML = msg;
                  this.URLVideo = "";
                  setTimeout(() => {
                    // this.URLVideo = { name: file.name, url: (<FileReader>event.target).result }
                    this.form.get('VideoFile').setValue([file])
                  }, 500);
                }
              }
            }
          }
        }


      } else {
        event.target.value = "";
        if (keyupload == 'video') return alert("ต้องเป็นวิดีโอเท่านั้น");
        if (keyupload == 'pdf') return alert("ต้องเป็น PDF นั้น");
        else alert("ต้องเป็นรูปภาพเท่านั้น");

      }

    }
  }

  getArrayItems(form: FormGroup, key: string) {
    return (<FormArray>form.get(key)).controls
  }

  createProduct() {
    let images = this.form.get(`community_prod_content_images`) as FormArray;
    if (images.controls.length > 0 && this.fileimages.length > 0) {
      images.controls.splice(0);
      images.reset();
    }
    if (this.fileimages.length > 0) this.fileimages.forEach(f => {
      images.push(this.build.group({
        community_prod_content_id: 0,
        community_prod_content_image_is_edit: true,
        community_prod_content_image: f
      }))
    })




    this.http.uploadfilePOST(`api/BOCommunityProduct`, this.form.value).subscribe(res => {
      this.router.navigate(['/', appUrl.product]);
    })
  }

  updateProduct() {
    let images = this.form.get(`community_prod_content_images`) as FormArray;
    if (images.controls.length > 0 && this.fileimages.length > 0) {
      images.controls.splice(0);
      images.reset();
    }
    if (this.fileimages.length > 0) this.fileimages.forEach(f => {
      images.push(this.build.group({
        community_prod_content_id: 0,
        community_prod_content_image_is_edit: true,
        community_prod_content_image: f
      }))
    })
    this.http.uploadfilePUT(`api/BOCommunityProduct/${this.proid}`, this.form.value).subscribe(res => {
      this.router.navigate(['/', appUrl.product]);
    })
  }

  cancel() {
    this.router.navigate(['/', appUrl.product]);
  }

  updateFileItems: any[] = [];
  deleteImg(items) {
    let images = this.form.get(`community_prod_content_images`) as FormArray;
    images.push(this.build.group({
      community_prod_content_id: items['name']['community_prod_content_id'],
      community_prod_content_image_is_edit: true,
      community_prod_content_image: ""
    }))
    this.arrayURLImages.splice(this.arrayURLImages.findIndex(del => del.name == items.name), 1);
    this.fileimages.splice(this.fileimages.findIndex(filedel => filedel.name == items.name), 1);
  }

  loadSize(items, img, input) {
    if (items.status == 'create') if (img.naturalWidth < 1900 && img.naturalHeight < 3000) {
      this.arrayURLImages.splice(this.arrayURLImages.findIndex(del => del.name == items.name), 1);
      this.fileimages.splice(this.fileimages.findIndex(filedel => filedel.name == items.name), 1);
      input.value = ""
      alert('ขนาดรูปไม่ตรงที่ต้องการ');
      return
    }
  }


  // width: 250px;
  // height: 400px;
}
