import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpService } from '../../../shareds/services/http.service';
import { appUrl } from '../../../app.url';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-add-contact',
  templateUrl: './add-contact.component.html',
  styleUrls: ['./add-contact.component.scss']
})
export class AddContactComponent implements OnInit {

  constructor(
    private dom: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    private build: FormBuilder,
    private http: HttpService,
    private router: Router) {
    this.formControl();
  }

  bootname: string;
  proid: number;
  active: string;
  productId: number;
  status: string;
  imRes: any;
  ngOnInit() {
    this.bootname = this.activatedRoute.snapshot.params['name'];
    this.proid = this.activatedRoute.snapshot.params['id'];
    this.active = this.activatedRoute.snapshot.params['action'];
    this.status = this.activatedRoute.snapshot.params['status'];
    this.productId = this.activatedRoute.snapshot.params['actionid'];
    this.formControl();
    if (this.proid) this.http.methodGet(`api/BOContact/${this.proid}`).subscribe(res => {
      // this.itemsContact = res['data']
      // console.log(this.itemsContact);

      res['data']['contact_other'].forEach(f => {
        this.buttonAddLink(false);
        if (f.contact_other_icon_img) f.contact_other_icon_url = { name: f.contact_other_icon_img, url: `${environment.images}/${f.contact_other_icon_img}` }

      })
      this.form.patchValue(res['data'])
      // console.log(this.form.value);

    })
  }

  form: FormGroup;
  formControl() {
    this.form = this.build.group({
      contact_detail: '',
      contact_isactive: true,
      // contact_other_icon_img: this.build.array([]),
      contact_other: this.build.array([])
    })
  }


  getArrayItems(form: FormGroup, key: string) {
    return (<FormArray>form.get(key)).controls;
  }

  buttonAddLink(type?: boolean) {
    console.log(type);

    let link = this.form.get('contact_other') as FormArray;
    if (!this.proid) link.push(this.build.group({
      contact_other_icon_img: 0,
      contact_other_icon_img_is_edit: type,
      contact_other_icon_url: "",
      contact_other_name: "",
      contact_other_link: ""
    }))

    if (this.proid) link.push(this.build.group({
      contact_other_id: 0,
      contact_other_icon_img: "",
      contact_other_icon_img_is_edit: type,
      contact_other_icon_url: "",
      contact_other_name: "",
      contact_other_link: ""
    }))

  }

  deleteLink(id) {
    let link = this.form.get('contact_other') as FormArray;
    link.removeAt(id);
  }

  URLimages: any;
  arrayURLImages: any = [];
  fileimages: any = [];
  progressBar: any;
  URLPdf: any;
  URLVideo: any
  selectIcon(event, type?: string, keyupload?: string, items?: any) {
    this.progressBar = 0;
    const feedback = document.getElementById('feedback');
    const progresss: any = document.getElementById('progress');
    const file = event.target.files && event.target.files[0];
    if (file) {
      // file.status = 'create';
      var reader = new FileReader();
      reader.readAsDataURL(file);
      if (file.type.indexOf(`${type}`) > -1) {
        if (keyupload != 'video') reader.onload = (event) => {
          if (keyupload == 'img') {
            items.get('contact_other_icon_img').setValue(file)
            items.get('contact_other_icon_img_is_edit').setValue(true)
            items.get('contact_other_icon_url').setValue({ name: file.name, url: (<FileReader>event.target).result })
          }
          if (keyupload == 'pdf') {
            this.URLPdf = ""
            this.URLPdf = { name: file.name, url: this.dom.bypassSecurityTrustUrl(URL.createObjectURL(file)) }
            this.form.get('PDFFile').setValue([file])
          }
        }
        if (keyupload == 'video') reader.onprogress = (event) => {
          if (event.loaded > 1153209714) {
            let msg = `< span style = "color:red;" > ไฟล์ขนาดเกินกำหนด < /span>`;
            feedback.innerHTML = msg;
            return;
          } else {
            if (event.loaded && event.total) {
              if (keyupload == 'video') {
                const percent = (event.loaded / event.total) * 100;
                progresss.value = percent;
                this.progressBar = progresss.value;
                document.getElementById('progress-label').innerHTML = Math.round(percent) + '%';
                if (percent === 100) {
                  let msg = `<span style="color:green;">File <u><b>${file.name}</b></u>.</span>`;
                  feedback.innerHTML = msg;
                  this.URLVideo = "";
                  setTimeout(() => {
                    // this.URLVideo = { name: file.name, url: (<FileReader>event.target).result }
                    this.form.get('VideoFile').setValue([file])
                  }, 500);
                }
              }
            }
          }
        }


      } else {
        event.target.value = "";
        if (keyupload == 'video') return alert("ต้องเป็นวิดีโอเท่านั้น");
        if (keyupload == 'pdf') return alert("ต้องเป็น PDF นั้น");
        else alert("ต้องเป็นรูปภาพเท่านั้น");

      }

    }
  }

  createContact() {
    // let d: any[];
    // let iconAll = this.form.get('contact_other_icon_img') as FormArray;
    // iconAll.controls.splice(0);
    // iconAll.reset()
    // let link = this.form.get('contact_other') as FormArray;
    // link.controls.forEach((f: any) => iconAll.push(f.controls['contact_other_icon_img']));
    this.form.value['contact_other'].forEach(f => {
      // delete f.contact_other_icon_img
      delete f.contact_other_icon_url
    })



    this.http.uploadfilePOST(`api/BOContact`, this.form.value).subscribe(res => {
      this.router.navigate(['/', appUrl.contact])
    })
  }

  updateContact() {
    this.form.value['contact_other'].forEach(f => {
      // delete f.contact_other_icon_img
      delete f.contact_other_icon_url
    })


    console.log(this.form.value);

    this.http.uploadfilePUT(`api/BOContact/${this.proid}`, this.form.value).subscribe(res => {
      this.router.navigate(['/', appUrl.contact])
    })
  }

  cancel() {
    this.router.navigate(['/', appUrl.contact])
  }
}
