import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { environment } from '../../../../../environments/environment';
declare let $;
@Component({
  selector: 'app-chat-booth',
  templateUrl: './chat-booth.component.html',
  styleUrls: ['./chat-booth.component.scss']
})
export class ChatBoothComponent implements OnInit, OnDestroy {

  constructor(private app: AppService,
    private activatedRoute: ActivatedRoute,
    private zone: NgZone) { }
  message: any[] = [
    { st: 'u', text: 'ชื่อไรคะ' },
    { st: 'i', text: 'ผมรวยครับ' },
    { st: 'u', text: 'แต่งคะ' },
    { st: 'u', text: 'แต่งวันไหนดีคะ' },
    { st: 'i', text: '?' },
    { st: 'u', text: 'คุณรวยใช่มั้ยคะ' },
    { st: 'i', text: 'ใช่ผมรวย' },
    { st: 'u', text: 'นั้นหละแต่งเลยคะ' },
    { st: 'i', text: 'ทำมั้ย' },
    { st: 'u', text: 'ก็คุณรวยไง' },
    { st: 'i', text: 'ผมไม่ได้รวย ผมชื่อรวย' }
  ];

  chat: any;
  boothid: number;
  users: any;
  chatMessage: any;
  ngOnInit() {
    this.boothid = this.activatedRoute.snapshot.params['id'];
    $.connection.hub.qs = { token: this.app.Authorization }
    $.connection.hub.url = environment.signalr;
    this.chat = $.connection.chatHub;
    this.chat.client.UpdateChatSingle = (res) => this.zone.run(() => {
      this.chatMessage = res.Replies;
      setTimeout(() => {
        let height = document.getElementsByClassName("box-message")[0].scrollHeight;
        document.getElementsByClassName("box-message")[0].scrollTop = 600 + height;
      }, 200);
    });

    this.chat.client.UpdateChatAll = (res) => this.zone.run(() => this.users = res);

    $.connection.hub.qs = { "token": this.app.Authorization }
    $.connection.hub.start().done((err, res) => {
      this.chat.server.init(this.boothid);
    });


  }

  ngOnDestroy() {
    $.connection.hub.stop()
  }

  tragetId: any;
  sendMessage(message: any, st?: string) {
    // this.message.push({ st: st || 'i', text: message.value || message });
    let height = document.getElementsByClassName("box-message")[0].scrollHeight;
    let top = document.getElementsByClassName("box-message")[0].scrollTop;
    document.getElementsByClassName("box-message")[0].scrollTop = 600 + height;
    this.chat.server.reply(this.tragetId, message.value);
    message.value = "";
  }

  callMessage(items) {
    // console.log(items);
    $.connection.hub.stop();
    $.connection.hub.start().done((err, res) => {
      this.tragetId = items.ChatId;
      this.chat.server.read(items.ChatId);
    });


  }

}
