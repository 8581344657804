import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { appUrl } from 'src/app/app.url';
import { HttpService } from 'src/app/shareds/services/http.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private http: HttpService,
    private build: FormBuilder,
    private app: AppService,
    private router: Router) { }
  width: number = 0;
  height: number = 0;
  ngOnInit() {
    setInterval(res => {
      // if (this.width == window.innerWidth || this.height == window.innerHeight) return;
      if (this.width != window.innerWidth || this.height != window.innerHeight) {
        this.width = window.innerWidth
        this.height = window.innerHeight
        let background = document.getElementById("background").getBoundingClientRect();
        let box = document.getElementById("box-longin");
        if (background.height > 760) {
          box.style.top = `${background.height / 3}px`;
        } else box.style.top = "12%";
      }

    })

    this.formControl();
  }

  form: FormGroup
  formControl() {
    this.form = this.build.group({
      Username: "",
      Password: ""
      // Username: "superadmin",
      // Password: "123456"

      // username: "superadmin",
      // password: "Addlink123!"
    })
  }

  Signin() {
    this.http.methodPost('api/BOAuthentication/SignIn', this.form.value).subscribe(res => {
      this.app.Authorization = res['Token'];
      this.app.RoleCode = res['data']['acc_role'];
      localStorage.setItem('botk', res['Token']);
      localStorage.setItem('rltk', res['data']['acc_role']);
      this.app.Reload.next();
      // this.router.navigate(['/', appUrl.home]);
    })
  }

}
