import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { appUrl } from 'src/app/app.url';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {

  constructor(private app: AppService,
    private router: Router) { }

  ngOnInit() {

  }

  addUser() {
    // this.router.navigate(['/', appUrl.mainchat, 'add'])
  }

}
