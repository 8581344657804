import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppService } from '../../app.service';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class HttpService {

  location = `${environment.url.replace(/\/$/, '')}`;
  constructor(private http: HttpClient, private app: AppService) { }




  get headers() {
    let headers = new HttpHeaders;
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Content-Type', 'application/json');
    if (this.app.Authorization) headers = headers.append('Authorization', ` Bearer ${this.app.Authorization}`);
    return headers;
  }

  private UploadFile(): HttpHeaders {
    let headers = new HttpHeaders();
    if (this.app.Authorization) headers = headers.append('Authorization', `Bearer ${this.app.Authorization}`);
    return headers;
  }

  private get ExportHeader(): HttpHeaders {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/pdf');
    if (this.app.Authorization) headers = headers.append('Authorization', `Bearer ${this.app.Authorization}`);
    return headers;
  }



  methodGet(url: string) {
    return this.http.get(`${this.location}/${url}`, { headers: this.headers });
  }

  methodGetExport(url: string) {
    return this.http.get(`${this.location}/${url}`, { headers: this.ExportHeader, responseType: 'blob' });
  }

  methodPostExport(url: string, model: any) {
    return this.http.post(`${this.location}/${url}`, model, { headers: this.ExportHeader, responseType: 'blob' });
  }

  uploadfilePOST(url: string, fileToUpload) {
    let formData: FormData = new FormData();
    for (let items in fileToUpload) {
      // console.log("--------------");
      // console.log(items);
      // console.log(typeof fileToUpload[items]);
      // console.log("--------------");
      if (typeof fileToUpload[items] == "object") {
        let i = 0;
        if (fileToUpload[items] != null) fileToUpload[items].forEach(f => {
          for (let n in f) {
            if (parseInt(n) >= 0) formData.append(`${items}[${[i]}]`, f[`${n}`])
            else formData.append(`${items}[${[i]}].${n}`, f[`${n}`])
          }
          i++;
        });


      }

      else formData.append(items, fileToUpload[items]);
    }
    return this.http.post(`${this.location}/${url}`, formData, { headers: this.UploadFile() });
  }

  uploadfilePOST2(url: string, fileToUpload) {
    let formData: FormData = new FormData();
    for (let items in fileToUpload) {
      if (typeof fileToUpload[items] == "object") {
        let i = 0;
        if (fileToUpload[items] != null) fileToUpload[items].forEach(f => {
          if (f.status == 'create') formData.append(`${items}`, f, f.name)
          if (f.status != 'create') {
            formData.append(`${items}.name`, f.name)
            formData.append(`${items}.status`, f.status)
          }
          i++;
        });


      }

      else formData.append(items, fileToUpload[items]);
    }
    return this.http.post(`${this.location}/${url}`, formData, { headers: this.UploadFile() });
  }

  uploadfilePUT(url: string, fileToUpload) {
    let formData: FormData = new FormData();
    for (let items in fileToUpload) {
      if (typeof fileToUpload[items] == "object") {

        if (fileToUpload[items] != null) fileToUpload[items].forEach(f => {
          // if (f.status == 'create') formData.append(`${items}[${[i]}]`, f, f.name)
          // if (f.status != 'create') {
          //   // formData.append(`${items}[${[i]}].name`, f.name)
          //   // formData.append(`${items}[${[i]}].status`, f.status)
          //   // formData.append(`${items}[${[i]}].name`, f.name)
          //   if (f.community_prod_content_name) formData.append(`${items}[${[i]}].community_prod_content_name`, f.community_prod_content_name)
          //   if (f.community_prod_content_detail) formData.append(`${items}[${[i]}].community_prod_content_detail`, f.community_prod_content_detail)
          //   if (f.status) formData.append(`${items}[${[i]}]`, f.status)
          // }
          let i = 0;
          if (fileToUpload[items] != null) fileToUpload[items].forEach(f => {
            for (let n in f) {
              console.log(n);

              if (parseInt(n) >= 0) formData.append(`${items}[${[i]}]`, f[`${n}`])
              else formData.append(`${items}[${[i]}].${n}`, f[`${n}`])
            }
            i++;
          });

        });

      }
      else formData.append(items, fileToUpload[items]);
    }
    return this.http.put(`${this.location}/${url}`, formData, { headers: this.UploadFile() });
  }

  uploadfilePUT2(url: string, fileToUpload) {
    let formData: FormData = new FormData();
    for (let items in fileToUpload) {
      if (typeof fileToUpload[items] == "object") {
        let i = 0;
        if (fileToUpload[items] != null) fileToUpload[items].forEach(f => {
          if (f.status == 'create') formData.append(`${items}`, f, f.name)
          if (f.status != 'create') {
            formData.append(`${items}.name`, f.name)
            formData.append(`${items}.status`, f.status)
          }
          i++;
        });
      }
      else formData.append(items, fileToUpload[items]);
    }
    return this.http.put(`${this.location}/${url}`, formData, { headers: this.UploadFile() });
  }

  methodPost(url: string, model: any) {
    return this.http.post(`${this.location}/${url}`, model, { headers: this.headers });
  }

  methodPut(url: string, model: any) {
    return this.http.put(`${this.location}/${url}`, model, { headers: this.headers });
  }

  methodDelete(url: string) {
    return this.http.delete(`${this.location}/${url}`, { headers: this.headers });
  }
}
