import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-demo',
  templateUrl: './demo.component.html',
  styleUrls: ['./demo.component.scss']
})
export class DemoComponent implements OnInit {
  btn = [
    { id: 1, name: 'ปลา', url: './assets/3D.html' },
    { id: 2, name: 'หุ่นยน', url: './assets/3D1.html' },
  ]
  constructor() { }

  ngOnInit() {

    // setTimeout(() => {
    //   document.getElementById("model").onload = (iframe) => {
    //     console.log(iframe);

    //     // iframe.contentDocument.body.prepend("Hello, world!");
    //   }
    // }, 500);
  }

  active: any;
  getItems(items: any) {
    document.getElementsByTagName('iframe')[0].remove();
    setTimeout(() => {
      this.active = items;
      var iframe = document.createElement('iframe');
      iframe.src = items.url
      iframe.setAttribute('class', 'iframe')
      document.getElementById('model').appendChild(iframe);
    }, 500);
    // console.log('iframe.contentWindow =', iframe.contentWindow);
  }

}
