import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { appUrl } from '../../app.url';
import { HttpService } from '../../shareds/services/http.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  constructor(private router: Router,
    private http: HttpService) { }

  ngOnInit() {
    this.getItems();
  }

  itemsContact: any = [];
  getItems() {
    this.http.methodGet(`api/BOContact/Find`).subscribe(res => {
      this.itemsContact = res['data']
    })
  }

  addContact() {
    this.router.navigate(['/', appUrl.contact, 'add']);
  }


  detail(items) {
    this.router.navigate(['/', appUrl.contact, 'update', items.contact_id]);
  }


  deleteProuct(items) {
    let txt;
    let alert = confirm("ต้องการลบ ?");
    if (alert == true) {
      this.http.methodDelete(`api/BOContact/${items.contact_id}`).subscribe(res => {
        this.getItems();
      })
    } else {
      txt = "You pressed Cancel!";
    }
  }
}
