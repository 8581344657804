import { Component, OnInit } from '@angular/core';
import { appUrl } from '../../app.url';
import { Router } from '@angular/router';
import { HttpService } from '../../shareds/services/http.service';

@Component({
  selector: 'app-schedulevents',
  templateUrl: './schedulevents.component.html',
  styleUrls: ['./schedulevents.component.scss']
})
export class ScheduleventsComponent implements OnInit {

  constructor(private router: Router, private http: HttpService) { }

  ngOnInit() {
    this.itemEvenet();
  }

  evenetItems: any = [];
  itemEvenet() {
    this.http.methodGet(`api/BOScheduleEvents/Find`).subscribe(res => {
      this.evenetItems = res['data'];
    })
  }

  addEvenet() {
    this.router.navigate(['/', appUrl.schedulevents, 'add'])
  }

  updateEvenet(items: any) {
    this.router.navigate(['/', appUrl.schedulevents, 'update', items.se_id])
  }

  deleteEvenet(items: any) {
    let txt;
    let alert = confirm("ต้องการลบ ?");
    if (alert == true) {
      this.http.methodDelete(`api/BOScheduleEvents/${items.se_id}`).subscribe(res => {
        txt = "You pressed OK!";
        this.itemEvenet();
      })
    } else {
      txt = "You pressed Cancel!";
    }
  }

}
