import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/shareds/services/http.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { appUrl } from '../../app.url';
import { DomSanitizer } from '@angular/platform-browser';
import { AppService } from '../../app.service';
declare let Twitch;
@Component({
  selector: 'app-livestream',
  templateUrl: './livestream.component.html',
  styleUrls: ['./livestream.component.scss']
})
export class LivestreamComponent implements OnInit {

  constructor(private http: HttpService,
    private build: FormBuilder,
    private router: Router,
    private sanitizer: DomSanitizer,
    public app: AppService) {

  }
  liveST: any;
  startItems: any;
  stream: any
  demo: string = `<iframe width="560" height="315" src="https://www.youtube.com/embed/M953xksaYGA" title="YouTube video player"
  frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
  allowfullscreen></iframe>`
  ngOnInit() {
    this.getLiveAll();
    setInterval(res => {
      if (!this.startItems) if (document.getElementById('twitch-embed1')) {
        new Twitch.Player("twitch-embed1", {
          channel: this.liveST,
          width: 500,
          height: 500,
          parent: ['bo.kasetsuphan.me', 'homkhajorn-abd.com']
        });
        this.startItems = true;
      }
    }, 1500)
  }

  changeName(items, name) {
    items.LiveStreamName = name.value
  }

  check(items) {
    items.UsePlayBack = !items.UsePlayBack;
  }

  playURL(items, url) {
    items.VideoPlayBackLink = url.value
  }

  getLiveAll() {
    this.http.methodGet(`api/BOLiveStream/Find`).subscribe(res => {
      this.stream = res['data'];
      this.stream.forEach(f => {
        this.liveST = f.ls_link_address_live
        f.live = this.sanitizer.bypassSecurityTrustHtml(f.ls_link_address)
      });
    })
  }

  checkStatus(items: any) {
    let model = {
      Name: items.ls_name,
      LinkStream: items.ls_link_stream,
      LinkAddressLive: items.ls_link_address_live,
      LinkAddress: items.ls_link_address,
      Detail: items.ls_detail,
      ShowStatus: !items.ls_isshow
    }
    this.http.methodPut(`api/BOLiveStream/${items.ls_id}`, model).subscribe(res => {
      this.getLiveAll();
    })
  }

  addLive() {
    this.router.navigate(['/', appUrl.livestreammanage, 'add'])
  }

  updateLive(items: any) {
    this.router.navigate(['/', appUrl.livestreammanage, 'update', items.ls_id])
  }

  deleteLive(items: any) {
    let txt;
    let alert = confirm("ต้องการลบ ?");
    if (alert == true) {
      this.http.methodDelete(`api/BOLiveStream/${items.ls_id}`).subscribe(res => {
        txt = "You pressed OK!";
        this.getLiveAll();
      })
    } else {
      txt = "You pressed Cancel!";
    }

  }

}
