export const appUrl = {
    home: "home",
    signin: "signin",
    users: "account",
    boothmanage: "booth",
    livestreammanage: "livestream",
    schedulevents: 'schedulevents',
    setting: "landingpagesetting",
    product: "communityproduct",
    contact: "contact",
    meetingroom: "meetingroom",
    demo: "demo",
}