import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './a-main-component/home/home.component';
import { LoginComponent } from './a-main-component/login/login.component';
import { appUrl } from './app.url';
import { AddAdminComponent } from './component/admin/add-admin/add-admin.component';
import { AdminComponent } from './component/admin/admin.component';
import { AddBoothComponent } from './component/booth/add-booth/add-booth.component';
import { BoothComponent } from './component/booth/booth.component';
import { DetailBoothComponent } from './component/booth/detail-booth/detail-booth.component';
import { AddFaqComponent } from './component/booth/detail-booth/faq-booth/add-faq/add-faq.component';
import { AddProductComponent } from './component/booth/detail-booth/product-booth/add-product/add-product.component';
import { LivestreamComponent } from './component/livestream/livestream.component';
import { AutorizationGuard } from './shareds/guards/autorization.guard';
import { UnautorizationGuard } from './shareds/guards/unautorization.guard';
import { SettingPageComponent } from './component/setting-page/setting-page.component';
import { ScheduleventsComponent } from './component/schedulevents/schedulevents.component';
import { DemoComponent } from './component/demo/demo.component';
import { AddButtonComponent } from './component/booth/detail-booth/booth-button/add-button/add-button.component';
import { AddScheduleventsComponent } from './component/schedulevents/add-schedulevents/add-schedulevents.component';
import { LiveStreamComponent } from './component/livestream/live-stream/live-stream.component';
import { ProductComponent } from './component/product/product.component';
import { AddProductMainComponent } from './component/product/add-product-main/add-product-main.component';
import { ContactComponent } from './component/contact/contact.component';
import { AddContactComponent } from './component/contact/add-contact/add-contact.component';
import { SeminarComponent } from './component/seminar/seminar.component';
import { AddSeminarComponent } from './component/seminar/add-seminar/add-seminar.component';

const routes: Routes = [
  { path: '', redirectTo: appUrl.signin, pathMatch: "full" },
  { path: appUrl.signin, component: LoginComponent, canActivate: [UnautorizationGuard] },
  { path: appUrl.home, component: HomeComponent, canActivate: [AutorizationGuard] },
  {
    path: appUrl.livestreammanage, canActivate: [AutorizationGuard], children: [
      { path: '', component: LivestreamComponent },
      { path: 'add', component: LiveStreamComponent },
      { path: 'update/:id', component: LiveStreamComponent },
    ]
  },
  {
    path: appUrl.users, canActivate: [AutorizationGuard], children: [
      { path: '', component: AdminComponent },
      { path: ':status', component: AddAdminComponent },
      { path: ':status/:id', component: AddAdminComponent },
      { path: ':status/:name/:booth', component: AddAdminComponent },
      { path: ':status/:name/:booth/:id', component: AddAdminComponent },
    ]
  },
  {
    path: appUrl.boothmanage, canActivate: [AutorizationGuard], children: [
      { path: '', component: BoothComponent },
      { path: ':status', component: AddBoothComponent },
      { path: ':status/:id', component: AddBoothComponent },
      { path: ':name/booth/:id', component: DetailBoothComponent },
      { path: ':name/booth/:id/:action', component: DetailBoothComponent },
      { path: ':name/booth/:id/:action/:status/product/:actionid', component: AddProductComponent },
      { path: ':name/booth/:id/:action/:status/faq/:actionid', component: AddFaqComponent },
      { path: ':name/booth/:id/:action/:status/button/:actionid', component: AddButtonComponent },
    ]
  },
  {
    path: appUrl.setting, canActivate: [AutorizationGuard], children: [
      { path: '', component: SettingPageComponent },
    ]
  },
  {
    path: appUrl.schedulevents, canActivate: [AutorizationGuard], children: [
      { path: '', component: ScheduleventsComponent },
      { path: 'add', component: AddScheduleventsComponent },
      { path: 'update/:id', component: AddScheduleventsComponent },
    ]
  },
  {
    path: appUrl.demo, canActivate: [AutorizationGuard], children: [
      { path: '', component: DemoComponent },
    ]
  },
  {
    path: appUrl.product, canActivate: [AutorizationGuard], children: [
      { path: '', component: ProductComponent },
      { path: 'add', component: AddProductMainComponent },
      { path: 'update/:id', component: AddProductMainComponent },
    ]
  },
  {
    path: appUrl.contact, canActivate: [AutorizationGuard], children: [
      { path: '', component: ContactComponent },
      { path: 'add', component: AddContactComponent },
      { path: 'update/:id', component: AddContactComponent },
    ]
  },
  {
    path: appUrl.meetingroom, canActivate: [AutorizationGuard], children: [
      { path: '', component: SeminarComponent },
      { path: 'add', component: AddSeminarComponent },
      { path: 'update/:id', component: AddSeminarComponent },
    ]
  },



];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
